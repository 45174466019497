import React, {useState} from "react";
import {Box, Grid, Button} from "@mui/material";
import TitleBar from "../../components/TitleBar";
import {useStopLiveChannelMutation} from "../../api/mediaLiveApi";
import Progress from "../../components/Progress";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {useNavigate} from 'react-router-dom';
import VideoJSPlayer from "../../features/VideoJSPlayer";

function LivePreview() {

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const navigate = useNavigate();

    const [stopMediaLiveChannel] = useStopLiveChannelMutation();

    const [isLoading, setIsLoading] = useState(false);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const event = urlParams.get('eventId');
    const videoId = urlParams.get('id');
    const hlsUrl = urlParams.get('url');
    const streamName = urlParams.get('title');


    console.log('id -', videoId);
    console.log('url -', hlsUrl);
    console.log('eventId -', event);
    const handleStopButtonClick = async () => {
        console.log('Button clicked!');
        try {
            setIsLoading(true);
            await stopMediaLiveChannel({channelId: `${videoId}`, liveEventId: `${event}`});
            navigate('/scheduled-live-list')
        } catch (error) {
            console.log('Error:', error);
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <Progress/>;
    }


    return (
        <>
            <Grid container spacing={2} sx={{mb: 2}}>
                <TitleBar title="Live Preview"/>

                <Grid item xs={12}>
                    <Box sx={{
                        pt: 2,
                        pl: 12,
                        pb: 2,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <Grid item xs={12}>
                            <Box sx={{
                                mr: '10%',
                                pl: '15%',
                                width: "60%",
                                height: "60%",
                            }}>
                                {/*<Typography variant='h4' sx={{align:'center', color:theme.palette.text.primary, pb:2 }} align="center">{streamName}</Typography>*/}

                                {/*<VideoPlayer hlsEndpoint={`${live.url}`}/>*/}

                                {/*<VideoJSPlayer hlsSrc={`${hlsUrl}`}*/}
                                {/*    // posterSrc="https://i.ytimg.com/vi/aqz-KE-bpKQ/maxresdefault.jpg"*/}
                                {/*/>*/}
                                <iframe allow="autoplay" src={`https://iframe.streameg.io/?source=${hlsUrl}&title=${streamName}&desc=`} style={{width:'100%', height:'68vh'}}></iframe>
                            </Box>
                        </Grid>

                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{
                        pb: 2,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <Button onClick={handleClickOpen} variant="contained" color="error"> End
                            Stream </Button>
                    </Box>


                    <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"


                    >
                        <DialogTitle id="responsive-dialog-title">

                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Typography>Are you sure you want to <b>End stream ?</b></Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{
                            m: '10px',
                            px: '10px'
                        }}>
                            <Button autoFocus onClick={handleStopButtonClick} color='info' variant='contained'
                                    size='small' sx={{

                                ml: '30px',
                                textTransform: 'capitalize',
                                background: theme.palette.secondary.main,
                                color: theme.palette.text.primary,
                                width: '100px',
                                '&:hover': {
                                    background: '#362365',
                                },

                            }}>
                                <Typography sx={{
                                    textTransform: 'capitalize'
                                }}>Yes</Typography>
                            </Button>
                            <Button onClick={handleClose} autoFocus color='info' variant='outlined' size='small' sx={{
                                color: 'white',
                                px: '30px',
                                width: '100px',

                            }}>
                                <Typography sx={{
                                    textTransform: 'capitalize'
                                }}>No</Typography>
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>

            </Grid>
        </>
    );
}

export default LivePreview;