import {Navigate, Outlet} from 'react-router-dom'
import {useSelector} from 'react-redux';
import {RootState} from "../../app/store";


const PrivateRoutes = (props: any) => {
    const accessToken = useSelector((state: RootState) => state.auth.accessToken);

    return (
        accessToken ? <Outlet/> : <Navigate to='/'/>
    )
}
export default PrivateRoutes



