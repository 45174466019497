import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface LoginSession {
    jwtToken: string
    payload: {
        sub: string
        'cognito:groups': string[]
        iss: string
        client_id: string
        origin_jti: string
        event_id: string
        token_use: string
        scope: string
        auth_time: number
        exp: number
        iat: number
        jti: string
        username: string
    }
}

interface signupSession {
    username:string
}

export type UserSession = {
    accessToken: LoginSession
    userEmail: string
}

type AuthState = {
    username:string
    userEmail: string
    accessToken: LoginSession | null
    error: string | null
    isLoading: boolean
    isSigningUp: boolean
    signupError: string | null
    isLogging: boolean
    loggingError: string | null
}

const initialState: AuthState = {
    accessToken: null,
    error: null,
    isLoading: false,
    isSigningUp: false,
    signupError: null,
    isLogging: false,
    loggingError: null,
    userEmail: '',
    username:'',
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginRequest: (state) => {
            state.isLoading = true
            state.error = null
        },
        loginSuccess: (state, action: PayloadAction<UserSession>) => {
            state.isLoading = false
            state.accessToken = action?.payload?.accessToken
            state.isLogging = true
            state.userEmail = action.payload.userEmail
            state.error = null
        },
        loginFailure: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.error = action.payload
        },
        logoutSuccess: (state) => {
            state.accessToken = null
            state.error = null
        },
        signupRequest: (state) => {
            state.isSigningUp = true
            state.signupError = null
        },
        signupSuccess: (state, action: PayloadAction<signupSession>) => {
            state.isSigningUp = false
            state.signupError = null
            state.username = action?.payload?.username
        },
        signupFailure: (state, action: PayloadAction<string>) => {
            state.isSigningUp = false
            state.signupError = action.payload
        },
    },
})

export const {
    loginRequest,
    loginSuccess,
    loginFailure,
    logoutSuccess,
    signupRequest,
    signupSuccess,
    signupFailure,
} = authSlice.actions

export default authSlice.reducer

export const selectAccessToken = (state: { auth: AuthState }) => state.auth

export const selectError = (state: { auth: AuthState }) => state.auth.error

export const selectIsLoading = (state: { auth: AuthState }) =>
    state.auth.isLoading

export const selectIsLogin = (state: { auth: AuthState }) =>
    state.auth.isLogging
