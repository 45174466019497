import {configureStore} from "@reduxjs/toolkit";
import {authApi} from "../api/authApi";
import {userApi} from '../api/userApi'
import authReducer from "../api/slices/authSlice";
import userReducer from "../api/slices/userSlice";
import liveReducer from "../api/slices/liveSlice";
import {eventApi} from "../api/eventApi";
import {mediaLiveApi} from "../api/mediaLiveApi";
import {mediaPackageApi} from "../api/mediaPackageApi";

// Define a function to save the state to local storage
function saveStateToLocalStorage(state: any) {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("reduxState", serializedState);
    } catch (e) {
        console.log(e);
    }
}

// Define a function to load the state from local storage
function loadStateFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem("reduxState");
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (e) {
        console.log(e);
        return undefined;
    }
}

// Load the state from local storage when creating the store
const persistedState = loadStateFromLocalStorage();

const store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        live: liveReducer,
        [authApi.reducerPath]: authApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [eventApi.reducerPath]: eventApi.reducer,
        [mediaLiveApi.reducerPath]: mediaLiveApi.reducer,
        [mediaPackageApi.reducerPath]: mediaPackageApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            authApi.middleware,
            userApi.middleware,
            eventApi.middleware,
            mediaLiveApi.middleware,
            mediaPackageApi.middleware,
        ),
    preloadedState: persistedState, // set the preloadedState with the persisted state
});
// Subscribe to the store to save the state to local storage whenever it changes
store.subscribe(() => {
    saveStateToLocalStorage(store.getState());
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
