import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {MuiOtpInput} from "mui-one-time-password-input";
import {Controller, useForm} from "react-hook-form";
import {Box, Button, Divider, FormHelperText, Grid, Typography} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useConfirmSignUpMutation} from "../../api/authApi";
import Progress from "../../components/Progress";
import store from "../../app/store";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as React from "react";
import {useNavigate} from 'react-router-dom';
import LoginLeftSideImage from "../../components/LoginLeftSideImage";
import {theme} from "../../layout/stylesheet";


const VerificationCode = () => {

    const {control, handleSubmit} = useForm({
        defaultValues: {
            code: "",
        },
    });

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [confirmSignUpError, setConfirmSignUpError] = useState("");
    const [confirmSignUp] = useConfirmSignUpMutation();

    useEffect(() => {
        if (confirmSignUpError) {
            toast.error(confirmSignUpError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }, [confirmSignUpError]);

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        setConfirmSignUpError("");

        try {
            const state = store.getState()
            const email = state.auth.username


            const result: any = await confirmSignUp({
                email: email || "",
                code: data.code,
            });

            if (result.data?.data === "SUCCESS") {
                toast.success('Verification Successful', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                navigate("/home");

            } else {
                setConfirmSignUpError("Verification Code Error.");

            }
        } catch (error) {
            console.log(error);
            setConfirmSignUpError("The entered verification code is incorrect.");

        }

        setIsLoading(false);
    };

    if (isLoading) {
        return <Progress/>;
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <Box sx={{
                width: '100%',
                position: 'relative',
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                height: '100vh'
            }}>

                <Box sx={{width: {sm:'90%',md:'90%',lg:'80%',xl:'75%'}, height: 'auto'}}>
                    <Grid container spacing={0}>
                        <Grid item xs={0} md={0} lg={4} xl={3} sx={{display:{xs:'none',sm:'none',lg:'flex'},
                            justifyContent:'right',
                        }}>      <LoginLeftSideImage />
                        </Grid>
                        <Grid item xs={12} md={12} lg={8} xl={9}>
                            <Box sx={{
                                backgroundColor: theme.palette.primary.dark,
                                borderRadius: {xs:'46px',sm:'46px',md:'46px',lg:'46px 0px 0px 46px'},
                                position: 'relative',
                                height:'auto',
                                boxShadow:8,
                                minHeight:'80vh'

                            }}>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    pt: 5,
                                    pl: 3,
                                    pr: 3
                                }}>
                                    <Link to="/">
                                        <ArrowBackIcon/>
                                    </Link>
                                    <Typography variant="h5" sx={{ml: 5,color: theme.palette.secondary.main}}>
                                        Enter account verification code
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignContent: 'center',
                                        mt: 20
                                    }}
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        alignContent: 'center',
                                        pt: 2,
                                        pl: 3,
                                        pr: 3
                                    }}>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <Controller
                                                name="code"
                                                control={control}
                                                rules={{validate: (value) => value.length === 6}}
                                                render={({field, fieldState}) => (
                                                    <Box sx={{flexDirection:'column',display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                        <MuiOtpInput sx={{gap: 2,width: '60%'}} {...field} length={6}/>
                                                        {fieldState.invalid ? (
                                                            <FormHelperText error>OTP invalid</FormHelperText>
                                                        ) : null}
                                                    </Box>
                                                )}
                                            />
                                            <Box  sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                pt: "40px",
                                            }}>
                                                <Button type="submit" variant="contained" sx={{mt: 2,marginBottom:'300px',color: theme.palette.text.primary, backgroundColor: theme.palette.secondary.main}}>
                                                    Submit
                                                </Button>
                                            </Box>
                                        </form>
                                    </Box>
                                </Box>

                                {/*<Box sx={{*/}
                                {/*    pt: 20,*/}
                                {/*    pl: 3,*/}
                                {/*    pr: 3*/}
                                {/*}}>*/}
                                {/*    <Divider/>*/}
                                {/*    <Box sx={{mt:2}}>*/}
                                {/*        <Link to="/forgot-password">*/}
                                {/*            <Button variant="text" type="submit" size="small"*/}
                                {/*                    style={{textTransform: 'none'}}>Resend*/}
                                {/*                OTP</Button>*/}
                                {/*        </Link>*/}
                                {/*    </Box>*/}
                                {/*</Box>*/}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>


        </>

    );
};

export default VerificationCode;
