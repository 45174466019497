import * as React from 'react';
import Typography from '@mui/material/Typography';
import TitleBar from "../../components/TitleBar";
import {Box, Button, Grid, MenuItem} from "@mui/material";
import ScheduleDetails from "../../components/go-live/ScheduleDetails";
import {Link} from "react-router-dom";
import SortIcon from "@mui/icons-material/Sort";
import {useEffect, useState} from "react";
import {useDeleteEventByIdMutation, useGetEventListQuery} from "../../api/eventApi";
import Progress from "../../components/Progress";
import AddIcon from '@mui/icons-material/Add';
import {
    useDeleteMediaLiveInputMutation,
    useDeleteMediaLiveChannelMutation,
    useStopLiveChannelMutation
} from "../../api/mediaLiveApi";
import {
    useDeleteMediaPackageChannelMutation,
    useDeleteMediaPackageEndpointMutation
} from "../../api/mediaPackageApi";

import {sortEvents} from "../../utils/sortEvents";
import Menu from "@mui/material/Menu";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {theme} from "../../layout/stylesheet";

type Props = {}


const ScheduleLiveList = (props: Props) => {
    const [deleteEventById] = useDeleteEventByIdMutation()
    const [mediaPackageChannelDelete] = useDeleteMediaPackageChannelMutation()
    const [mediaPackageEndpointDelete] = useDeleteMediaPackageEndpointMutation()
    const [mediaLiveInputDelete] = useDeleteMediaLiveInputMutation()
    const [mediaLiveChannelDelete] = useDeleteMediaLiveChannelMutation()
    const [mediaLiveChannelStop] = useStopLiveChannelMutation()


    const [isLoading, setIsLoading] = useState(false);
    const {data: eventList, refetch} = useGetEventListQuery()

    const [sortBy,setSortBy] = useState('time');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const sortClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const sortClose = () => {
        setAnchorEl(null);
    };

    const setOrderbyName = ()=>{
        setSortBy('name');
        //window.location.reload();
    }
    const setOrderbyTime = ()=>{
        setSortBy('time');
        //window.location.reload();
    }
    useEffect(() => {

        refetch();
        console.log("sorted",eval(sortEvents(JSON.stringify(eventList), 'time')));

    }, [refetch]);


    const handleDeleteEvent = async (eventId: string) => {

        const selectedEvent = eventList?.find((item) => {
            return item.event.id === eventId;
        });
        console.log("Selected event",selectedEvent);
        if (selectedEvent) {
            console.log(`This is selected event : ${JSON.stringify(selectedEvent)}`);

            try {
                setIsLoading(true);
                console.log(selectedEvent);
                const endpointId = selectedEvent?.liveStreams?.output?.id;
                console.log(`endpointId ${endpointId}`)

                const mediaPackageChannelId = selectedEvent?.liveStreams?.output?.id;
                console.log(`mediaPackageChannelId ${mediaPackageChannelId}`)

                const mediaLiveInputId = selectedEvent?.liveStreams?.input?.id;
                console.log(`mediaLiveInputId ${mediaLiveInputId}`)

                const mediaLiveChannelId = selectedEvent?.liveStreams?.channel?.id;
                console.log(`mediaLiveChannelId ${mediaLiveChannelId}`)

                const eventId = selectedEvent?.liveStreams?.eventId;
                console.log(`eventId ${eventId}`)

                const id = selectedEvent?.liveStreams?.id;
                console.log(`eventId ${id}`)

                const channelId = selectedEvent?.liveStreams?.channel?.id;
                console.log(`channelId ${channelId}`)

                if (mediaLiveChannelId ) {
                    console.log(mediaLiveChannelId," channel is available");
                    //await mediaLiveChannelStop({channelId})
                    const res = await mediaLiveChannelDelete({mediaLiveChannelId});
                    console.log(res);
                    await mediaPackageEndpointDelete({endpointId});
                    await mediaPackageChannelDelete({mediaPackageChannelId});
                    await mediaLiveInputDelete({mediaLiveInputId});

                } else {console.log(mediaLiveChannelId," channel is unavailable");
                    await mediaPackageEndpointDelete({endpointId});
                    await mediaPackageChannelDelete({mediaPackageChannelId});
                    await mediaLiveInputDelete({mediaLiveInputId});
                    if(mediaLiveChannelId){
                        await mediaLiveChannelDelete({mediaLiveChannelId});
                    }

                }

                await deleteEventById({eventId,id});
                console.log(`Event delete test : ${eventId},${id}`);
                setIsLoading(false);

                toast.success('Delete successful', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setTimeout(()=>{
                    window.location.reload();
                },2000);

            } catch (error) {
                console.log(`Error deleting event with ID ${eventId}:`, error);
            }
        } else {
            console.log(`Event with ID ${eventId} not found.`);
        }
        setIsLoading(false);
    }

    if (isLoading) {
        return <Progress/>
    }
    return (

        <Grid container spacing={2}>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <TitleBar title="Scheduled Live List"/>

            <Grid
                item xs={12}>
                <Box sx={{
                    pt: 2,
                    pl: 6,
                    pr: 6,
                    pb: 2,
                   
                }}>
                    <Box>
                       
                        <Box display="flex" alignItems="center" sx={{
                            paddingBottom: "0px", 
                            display: 'flex', 
                            justifyContent: 'flex-end',
                                                    
                    }}>
                            <Link to="/schedule-live">
                                <Button variant='outlined' color='secondary'  sx={{
                                     color:theme.palette.secondary.main,
                                     borderColor:theme.palette.secondary.main,
                                     textTransform:'capitalize'
                                    
                                   
                                }}>
                                <AddIcon  sx={{ 
                                    color:theme.palette.secondary.main,
                                    mr:1,
                                }}/>

                                    Schedule New Live
                                </Button>
                            </Link>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            mt: '2%',
                            p: 0
                        }}>
                            <Link to="#">
                                <Button sx={{textAlign: "right", justifyContent: "flex-end"}}id="demo-positioned-button"
                                        aria-controls={open ? 'demo-positioned-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={sortClick}>
                                    Sort by
                                    <SortIcon/>
                                </Button>
                            </Link>
                            <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={sortClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <MenuItem  onClick={setOrderbyName}>Sort By Title</MenuItem>
                                {/*<MenuItem onClick={setOrderbyTime}>Sort By Time</MenuItem>*/}

                            </Menu>
                        </Box>
                        {/* <Typography variant='h4' sx={{mb: 2, color: theme.palette.primary.light}}>Previous Schedule</Typography> */}

                        <Typography>

                            {/*eval(sortEvents(JSON.stringify(eventList),sortBy))*/}
                            {Array.isArray(eventList) && eval(sortEvents(JSON.stringify(eventList), sortBy))?.map((event:any) => (

                                <ScheduleDetails key={event.id} eventData={event}
                                                 onDelete={() =>handleDeleteEvent(event?.event?.id)}  refetch={refetch}/>
                            ))}


                        </Typography>
                    </Box>
                </Box>
            </Grid>

        </Grid>
    )
}
export default ScheduleLiveList