import {
    Box,
    Button,
    IconButton, InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import {Link} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useState} from "react";
import {useLoginMutation} from "../api/authApi";
import Progress from "./Progress";
import Alert from "@mui/material/Alert";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as React from "react";
import {useNavigate} from 'react-router-dom';
import {theme} from "../layout/stylesheet";

type LoginFormValues = {
    username: string;
    password: string;
};

const Login = () => {
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<LoginFormValues>({mode: "onBlur"});

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [loginError, setLoginError] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const [login] = useLoginMutation();


    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };
    const onSubmit = async (data: LoginFormValues) => {
        setIsLoading(true);
        setLoginError("");

        try {
            const result: any = await login(data);
            console.log(result);

            if (result.data?.data.idToken) {
                // toast.success('Login successfull', {
                //     position: "top-right",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "colored",
                //
                // });
               // window.location.href = "/schedule-live";
                navigate('/home');

                // setIsLoading(false);
                // setInterval(function(){
                //     window.location.href = "/schedule-live";
                // }, 200);

            }else if (result.error.data.result.code === "UserNotConfirmedException"){
                console.log(result.error.data.result.code)
                navigate('/account-verification')
            }else if (result.error.data.result.code === "UserNotFoundException"){
                console.log(result.error.data.result.code)
                toast.error('The email or password you entered doesn\'t match our records.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }

            else {
                // console.log(result['error']['data']);
                toast.error('Invalid User Email or Password', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setLoginError('Invalid User Email or Password');

            }

        } catch (error) {
            console.log(error);
            setLoginError("Invalid User Email or Password");
            toast.error("Invalid User Email or Password", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }

        setIsLoading(false);
    };

    if (isLoading) {
        return <Progress/>;
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <div
                style={{
                    // width: "675px",
                    // height: "616px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        width:{xs:'65%',sm:'65%',md:'50%',lg:'50%',xl:'50%'},
                        pt: 5,
                        pb: 5,
                        pl:5,
                        pr:5,
                        mb:10,
                        borderRadius: "25px",
                        boxShadow: "0px 4px 10px 10px rgba(0, 0, 0, 0.25)",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: theme.palette.primary.dark,
                        border: "2px solid",
                        borderColor:theme.palette.secondary.main

                    }}
                >
                    <Typography sx={{pb:5, color: theme.palette.text.primary}}>Sign in with your email account</Typography>

                        <form onSubmit={handleSubmit(onSubmit)} style={{width:'100%'}}>
                            <div style={{alignItems: "center", justifyContent: "center"}}>
                                <TextField
                                    sx={{width: "100%", height: "50px"}}
                                    label="User Email"
                                    {...register("username", {
                                        required: true,
                                        pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,

                                })}
                                error={!!errors.username}
                                helperText={
                                    errors.username ? "Please enter a valid email address" : ""
                                }
                            />
                        </div>
                        <Box sx={{pt: "40px"}}>
                            <TextField
                                sx={{width: "100%", height: "50px"}}
                                label="Password"
                                type={showPassword ? "text" : "password"}
                                {...register("password", {required: true})}
                                error={!!errors.password}
                                autoComplete="off"
                                helperText={errors.password && "Password is required"}
                                InputProps={{ // <-- This is where the toggle button is added.
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                        <Box sx={{display: "flex",  justifyContent: "right",pt: "20px"}}>
                            <Link to="/forgot-password" style={{
                                color:theme.palette.link,
                                fontWeight:'800',
                                textDecoration:'none'
                            }}>Forgot Password?</Link>
                        </Box>

                        {/*<FormGroup>*/}
                        {/*  <FormControlLabel*/}
                        {/*    control={<Checkbox defaultChecked />}*/}
                        {/*    label="Remember me"*/}
                        {/*  />*/}
                        {/*</FormGroup>*/}
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                                marginTop: "20px",
                                paddingBottom: "10px",
                            }}
                        >
                            <Button sx={{color: theme.palette.text.primary, backgroundColor: theme.palette.secondary.main}} type="submit" variant="contained">
                                Sign in
                            </Button>
                        </div>
                    </form>

                    <Typography sx={{pt:2, color: theme.palette.text.primary}}>
                        Don't have an account? <Link to="/register" style={{
                        color:theme.palette.link,
                        fontWeight:'800',
                        textDecoration:'none'
                    }}>Sign Up Now</Link>
                    </Typography>
                </Box>
            </div>

</>
    );
};

export default Login;
