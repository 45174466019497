import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {API_PATHS} from '../configs/api'

export const mediaPackageApi = createApi({
    reducerPath: 'mediaPackageApi',
    baseQuery: fetchBaseQuery({
        baseUrl: API_PATHS.BASE_URL,
        prepareHeaders: (headers, {getState}) => {
            const accessToken = (getState() as any).auth.accessToken
            if (accessToken && accessToken.jwtToken) {
                headers.set('Authorization', `Bearer ${accessToken.jwtToken}`)
            }
            return headers
        },
    }),
    endpoints: (builder) => ({
        CreatePackageChannel: builder.mutation<CreateChannelResponse, CreateChannelRequest>({
            query: ({eventId, Id}) => ({
                url: `${API_PATHS.MEDIA_PACKAGE.CREATE_CHANNEL}?eventId=${eventId}&id=${Id}`,
                method: "POST",
            }),
            async onQueryStarted({eventId, Id}, {queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    console.log(data);
                } catch (error) {
                    console.log(`create event error`);
                }
            },
        }),
        createEndPoint: builder.mutation<CreateEndPointResponse, CreateEndPointRequest>({
            query: ({eventId, channelId}) => ({
                url: `${API_PATHS.MEDIA_PACKAGE.CREATE_END_POINT}?eventId=${eventId}&channelId=${channelId}`,
                method: "POST",
            }),
            async onQueryStarted({eventId, channelId}, {queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    console.log(data);
                } catch (error) {
                    console.log(`create event error`);
                }
            },
        }),
        deleteMediaPackageEndpoint: builder.mutation<void, DeleteMediaPackageEndpoint>({
            query: ({endpointId}) => ({
                url: `${API_PATHS.MEDIA_PACKAGE.DELETE_END_POINT}?channelId=${endpointId}`,
                method: "DELETE",
            }),
        }),
        deleteMediaPackageChannel: builder.mutation<void, DeleteMediaPackageChannel>({
            query: ({mediaPackageChannelId}) => ({
                url: `${API_PATHS.MEDIA_PACKAGE.DELETE_CHANNEL}?id=${mediaPackageChannelId}`,
                method: "DELETE",
            }),
        }),
    }),
})

export const {useCreatePackageChannelMutation, useCreateEndPointMutation, useDeleteMediaPackageChannelMutation, useDeleteMediaPackageEndpointMutation} = mediaPackageApi

interface CreateChannelResponse {
    eventId: string
    Id: string
}

interface CreateChannelRequest {
    eventId: string
    Id: string
}

interface CreateEndPointResponse {
    eventId: string
    channelId: string
}

interface CreateEndPointRequest {
    eventId: string
    channelId: string
}

interface DeleteMediaPackageEndpoint {
    endpointId: string
}

interface DeleteMediaPackageChannel {
    mediaPackageChannelId: string
}
